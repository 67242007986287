import * as Ord_ from "fp-ts/lib/Ord";

export type Normal<normal> = {
    type: "normal";
    value: normal;
};
export const normal_ = <normal>(value: normal): Normal<normal> => ({
    type: "normal",
    value,
});
export const getNormalOrd = <normal>(
    normalOrd: Ord_.Ord<normal>,
): Ord_.Ord<Normal<normal>> =>
    Ord_.fromCompare((a, b) => normalOrd.compare(a.value, b.value));

export type Travis<travis> = {
    type: "travis";
    value: travis;
};
export const travis_ = <travis>(value: travis): Travis<travis> => ({
    type: "travis",
    value,
});
export const getTravisOrd = <travis>(
    travisOrd: Ord_.Ord<travis>,
): Ord_.Ord<Travis<travis>> =>
    Ord_.fromCompare((a, b) => travisOrd.compare(a.value, b.value));

export type NormalOrTravis<normal, travis> = Normal<normal> | Travis<travis>;

export const normal = <normal, travis>(
    value: normal,
): NormalOrTravis<normal, travis> => normal(value);
export const isNormal = <normal>(
    not: NormalOrTravis<normal, unknown>,
): not is Normal<normal> => not.type === "normal";

export const travis = <normal, travis>(
    value: travis,
): NormalOrTravis<normal, travis> => ({
    type: "travis",
    value,
});
export const isTravis = <travis>(
    not: NormalOrTravis<unknown, travis>,
): not is Travis<travis> => not.type === "travis";

export const getOrd = <normal, travis>(
    normalOrd: Ord_.Ord<normal>,
    travisOrd: Ord_.Ord<travis>,
): Ord_.Ord<NormalOrTravis<normal, travis>> =>
    Ord_.fromCompare((a, b) =>
        isNormal(a)
            ? isNormal(b)
                ? normalOrd.compare(a.value, b.value)
                : -1
            : isTravis(a)
              ? isTravis(b)
                  ? travisOrd.compare(a.value, b.value)
                  : 1
              : (0 as never),
    );
export const fold =
    <normal, travis, a>(normal: (v: normal) => a, travis: (v: travis) => a) =>
    (v: NormalOrTravis<normal, travis>): a =>
        v.type === "normal"
            ? normal(v.value)
            : v.type === "travis"
              ? travis(v.value)
              : (undefined as never);
