import * as num from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";

import { Branded, Int } from "../../../../utils/Brand";
import { ContactPoint } from "./ContactPoint";
import { FuelTypeId } from "./FuelType";
import { GasStationTypeId } from "./GasStationType";
import { Geo } from "./Geo";
import { Normal } from "./NormalOrTravis";
import { NormalServiceId } from "./NormalService";
import { PaymentMethodId } from "./PaymentMethod";
import { PostalAddress } from "./PostalAddress";

export type PriceIdBrand = { readonly PriceId: unique symbol };
export type PriceId = Branded<Int, PriceIdBrand>;

export type RatioIdBrand = { readonly RatioId: unique symbol };
export type RatioId = Branded<Int, RatioIdBrand>;

export type GasStationIdBrand = { readonly GasStationId: unique symbol };
export type GasStationId = Branded<Int, GasStationIdBrand>;

export type GasStation = {
    id: Normal<GasStationId>;
    geo: Geo;
    /** @deprecated Use slug instead */
    url: string;
    name: string;
    slug: string;
    type: Normal<GasStationTypeId>;
    address: PostalAddress;
    hasMenu: boolean;
    premium: boolean;
    priceId: PriceId;
    ratioId: RatioId | null;
    contactPoint: Omit<ContactPoint, "hoursAvailable">;
    availableServices: ReadonlyArray<Normal<NormalServiceId>>;
    availableFuelTypes: ReadonlyArray<FuelTypeId>;
    supportedPaymentMethods: ReadonlyArray<PaymentMethodId>;
};

export const IdOrd = num.Ord as Ord_.Ord<GasStationId>;
