import * as num from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";

import { Branded, Int } from "../../../../utils/Brand";
import { ContactPoint } from "./ContactPoint";
import { FuelTypeId } from "./FuelType";
import { Geo } from "./Geo";
import { Travis } from "./NormalOrTravis";
import { PaymentMethodId } from "./PaymentMethod";
import { PostalAddress } from "./PostalAddress";
import { ServiceId } from "./Service";
import { TravisStationTypeId } from "./TravisStationType";

export type TravisStationIdBrand = { readonly TravisStationId: unique symbol };
export type TravisStationId = Branded<Int, TravisStationIdBrand>;

export type TravisStation = {
    id: Travis<TravisStationId>;
    geo: Geo;
    name: string;
    type: Travis<TravisStationTypeId>;
    address: Omit<PostalAddress, "country">;
    contactPoint: ContactPoint;
    availableServices: ReadonlyArray<ServiceId>;
    availableFuelTypes: ReadonlyArray<FuelTypeId> & { length: 0 };
    supportedPaymentMethods: ReadonlyArray<PaymentMethodId> & { length: 0 };
};

export const IdOrd = num.Ord as Ord_.Ord<TravisStationId>;
