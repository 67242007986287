import * as num from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";

import { Branded, Int } from "../../../../utils/Brand";

export type PaymentMethodIdBrand = { readonly PaymentMethodId: unique symbol };
export type PaymentMethodId = Branded<Int, PaymentMethodIdBrand>;

export type PaymentMethod = {
    id: PaymentMethodId;
    name: string;
};

export const IdOrd = num.Ord as Ord_.Ord<PaymentMethodId>;
export const Ord = Ord_.fromCompare<PaymentMethod>((a, b) =>
    num.Ord.compare(a.id, b.id)
);
