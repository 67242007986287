import React, { FC, useEffect } from "react";

export type BlockStylesProps = {
    blockedPrefixes: string[] & { 0: string };
};

const BlockStyles: FC<BlockStylesProps> = ({ blockedPrefixes }) => {
    useEffect(() => {
        const head = typeof window !== "undefined" && window.document?.head;

        if (!head) return;

        const { insertBefore } = head;

        head.insertBefore = function (newElement, ...args) {
            if (
                newElement instanceof HTMLLinkElement &&
                blockedPrefixes.some((pfx) => newElement.href.startsWith(pfx))
            ) {
                console.info("Prevented style from loading!", newElement);
            } else {
                insertBefore.call(this, newElement, ...args);
            }

            return newElement;
        };

        return () => {
            Object.assign(head, { insertBefore });
        };
    }, [blockedPrefixes]);

    return null;
};
export default BlockStyles;
