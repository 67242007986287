import {
    FuelType,
    NormalService,
    PaymentMethod,
    Station,
    StationType,
} from "../components/pages/GasStations/types/index";
import fetcher, { DataResponse, urlPath } from "./fetcher";

const gs = fetcher.scoped(urlPath`/gas-stations`);

export type GasStationsRequest = Record<string, never>;
export type GasStationsResponse = DataResponse<ReadonlyArray<Station>>;
export const gasStations = gs.errorAware<
    GasStationsRequest,
    GasStationsResponse
>(urlPath`/`, "GET");

export type FuelTypesRequest = Record<string, never>;
export type FuelTypesResponse = DataResponse<ReadonlyArray<FuelType>>;
export const fuelTypes = gs.errorAware<FuelTypesRequest, FuelTypesResponse>(
    urlPath`/fuel-types/`,
    "GET",
);

export type ServicesRequest = Record<string, never>;
export type ServicesResponse = DataResponse<ReadonlyArray<NormalService>>;
export const services = gs.errorAware<ServicesRequest, ServicesResponse>(
    urlPath`/services/`,
    "GET",
);

export type PaymentMethodsRequest = Record<string, never>;
export type PaymentMethodsResponse = DataResponse<ReadonlyArray<PaymentMethod>>;
export const paymentMethods = gs.errorAware<
    PaymentMethodsRequest,
    PaymentMethodsResponse
>(urlPath`/payment-methods/`, "GET");

export type StationTypesRequest = Record<string, never>;
export type StationTypesResponse = DataResponse<ReadonlyArray<StationType>>;
export const stationTypes = gs.errorAware<
    StationTypesRequest,
    StationTypesResponse
>(urlPath`/types/`, "GET");
