import * as bool from "fp-ts/lib/boolean";
import * as Eq from "fp-ts/lib/Eq";
import * as num from "fp-ts/lib/number";
import * as St from "fp-ts/lib/ReadonlySet";
import * as str from "fp-ts/lib/string";

import { navigate } from "gatsby";

import { type HLocation, type NavigateOptions } from "@reach/router";

import * as gasStations from "../../pages/GasStations/store";
import * as FuelType from "../../pages/GasStations/types/FuelType";
import {
    GasStationId,
    NormalServiceId,
    TravisServiceId,
} from "../../pages/GasStations/types/index";
import * as NormalOrTravis from "../../pages/GasStations/types/NormalOrTravis";
import * as Property from "../../pages/GasStations/types/Property";

// This implicitly expects that wl actually is a valid location to be parsed into `a`
export type FromWindowLocation<rt, st = unknown> = (wl: HLocation<st>) => rt;
export type RouteFormatter<rt> = (rt: rt) => string;

export type Query = Partial<
    Record<string, string | number | string[] | number[] | boolean>
>;
export const toURLWithSearch = (base: string | URL, query: Query) => {
    const url = new URL(
        base,
        typeof window === "undefined" ? undefined : window.location.href
    );
    for (const [k, v] of Object.entries(query)) {
        if (v == null) {
            url.searchParams.delete(k);
            continue;
        }

        if (Array.isArray(v)) url.searchParams.set(k, v.map(String).join(","));
        else url.searchParams.set(k, String(v));
    }
    return url;
};

const urlToRelative = (url: URL) => {
    if (
        `${url.protocol}//${url.host}` ===
        `${window.location.protocol}//${window.location.host}`
    ) {
        return `${url.pathname}${url.search}${url.hash}`;
    }
    return url.href;
};

const formatRelativeUrl = (segments: string[], query: Query = {}) =>
    urlToRelative(toURLWithSearch("/" + segments.join("/") + "/", query));

export type RouteFinder = {
    location: string | undefined;
    fuelTypes: ReadonlySet<FuelType.FuelTypeId> | undefined;
    properties: ReadonlySet<Property.PropertyId> | undefined;
    hoyerStationTypes: ReadonlySet<gasStations.HoyerStationType> | undefined;
    acceptancePartnerStationTypes:
        | ReadonlySet<gasStations.AcceptancePartnerStationType>
        | undefined;
    premium: boolean | undefined;
    paymentTypes: ReadonlySet<gasStations.PaymentType> | undefined;
    map: {
        center: google.maps.LatLngLiteral | undefined;
        zoom: number | undefined;
    };
};
export const routeFinderFromWindowLocation: FromWindowLocation<RouteFinder> = (
    wl
) => {
    const params = new URLSearchParams(wl.search);

    const location = params.get("location");
    const properties = new Set(
        (params.get("properties") ?? "")
            .split(",")
            .filter((prop) => !!prop)
            .map((prop): Property.PropertyId => {
                const [type, id] = prop.split("-") as [
                    "travis" | "normal",
                    string
                ];

                return {
                    travis:
                        !id || id === "0" || id === "travis"
                            ? Property.travisPropertyId
                            : id === "tankCleaning"
                            ? Property.servicePropertyId(
                                  NormalOrTravis.travis_(
                                      TravisServiceId.tankCleaning
                                  )
                              )
                            : (undefined as never),
                    normal: Property.servicePropertyId(
                        NormalOrTravis.normal_(Number(id) as NormalServiceId)
                    ),
                }[type];
            })
    );
    const hoyerStationTypes = params.has("hoyerStationTypes")
        ? new Set(
              (params.get("hoyerStationTypes") ?? "")
                  .split(",")
                  .filter(
                      (prop): prop is gasStations.HoyerStationType => !!prop
                  )
          )
        : undefined;
    const acceptancePartnerStationTypes = params.has(
        "acceptancePartnerStationTypes"
    )
        ? new Set(
              (params.get("acceptancePartnerStationTypes") ?? "")
                  .split(",")
                  .filter(
                      (
                          prop
                      ): prop is gasStations.AcceptancePartnerStationType =>
                          !!prop
                  )
          )
        : undefined;
    const fuelTypes = new Set(
        (params.get("fuelTypes") ?? "")
            .split(",")
            .filter((prop) => !!prop)
            .map(
                (val): FuelType.FuelTypeId => Number(val) as FuelType.FuelTypeId
            )
    );
    const paymentTypes = new Set(
        (params.get("paymentTypes") ?? "")
            .split(",")
            .filter((prop): prop is gasStations.PaymentType => !!prop)
    );
    const { lat, lng } = params.has("center")
        ? Object.fromEntries(
              (params.get("center") ?? "")
                  .split(",")
                  .map(Number)
                  .map((val, i) => [i === 0 ? "lat" : "lng", val] as const)
                  .filter(([, val]) => !isNaN(val))
          )
        : ({} as { lat?: number; lng?: number });

    return {
        location: location === "27374" ? "DE-27374" : location || undefined,
        properties: properties.size ? properties : undefined,
        hoyerStationTypes,
        acceptancePartnerStationTypes,
        fuelTypes: fuelTypes.size ? fuelTypes : undefined,
        premium: params.has("premium")
            ? params.get("premium") === "true"
            : undefined,
        paymentTypes: paymentTypes.size ? paymentTypes : undefined,
        map: {
            center: lat == null || lng == null ? undefined : { lat, lng },
            zoom: params.has("zoom")
                ? Number(params.get("zoom") ?? "")
                : undefined,
        },
    };
};
const setVal = <a>(as: ReadonlySet<a> | undefined): a[] | undefined =>
    as == null ? undefined : Array.from(as);
const nonEmptySetVal = <a>(as: ReadonlySet<a> | undefined): a[] | undefined =>
    as == null || as.size === 0 ? undefined : Array.from(as);

export const formatRouteFinder: RouteFormatter<RouteFinder> = ({
    location,
    fuelTypes,
    properties,
    hoyerStationTypes,
    acceptancePartnerStationTypes,
    premium,
    paymentTypes,
    map: { center, zoom },
}) =>
    formatRelativeUrl(["tankstellen", "finder"], {
        location,
        center: center ? `${center.lat},${center.lng}` : undefined,
        zoom,
        fuelTypes: nonEmptySetVal(fuelTypes),
        properties: nonEmptySetVal(properties)?.map(
            Property.foldId(
                NormalOrTravis.fold(
                    (id) => `normal-${id}`,
                    (id) => `travis-${id}`
                ),
                () => "travis"
            )
        ),
        hoyerStationTypes: setVal(hoyerStationTypes),
        acceptancePartnerStationTypes: setVal(acceptancePartnerStationTypes),
        premium,
        paymentTypes: nonEmptySetVal(paymentTypes),
    });
const getUndefinedEq = <a>(eq: Eq.Eq<a>): Eq.Eq<a | undefined> =>
    Eq.fromEquals((a, b) =>
        a === undefined
            ? b === undefined
                ? true
                : false
            : b === undefined
            ? false
            : eq.equals(a, b)
    );
export const RouteFinderEq = Eq.struct<RouteFinder>({
    location: getUndefinedEq(str.Eq),
    properties: getUndefinedEq(St.getEq(Property.IdOrd)),
    hoyerStationTypes: getUndefinedEq(
        St.getEq(gasStations.HoyerStationTypeOrd)
    ),
    acceptancePartnerStationTypes: getUndefinedEq(
        St.getEq(gasStations.AcceptancePartnerStationTypeOrd)
    ),
    fuelTypes: getUndefinedEq(St.getEq(FuelType.IdOrd)),
    premium: getUndefinedEq(bool.Eq),
    paymentTypes: getUndefinedEq(St.getEq(gasStations.PaymentOrd)),
    map: Eq.struct<RouteFinder["map"]>({
        center: getUndefinedEq(
            Eq.struct({
                lat: num.Eq,
                lng: num.Eq,
            })
        ),
        zoom: getUndefinedEq(num.Eq),
    }),
});

export type RouteGasStation = {
    slug: string;
    id: GasStationId | undefined;
};
export const routeGasStationFromWindowLocation: FromWindowLocation<
    RouteGasStation
> = (wl) => {
    const slug = wl.pathname.split("/").filter(Boolean).pop() as string;
    const id = slug.split("|").pop();

    return {
        slug,
        id: id ? (Number(id) as GasStationId) : undefined,
    };
};
export const formatRouteGasStation: RouteFormatter<RouteGasStation> = (r) =>
    formatRelativeUrl(["tankstelle", r.slug]);

export type Routes = {
    RouteFinder: RouteFinder;
    RouteGasStation: RouteGasStation;
};
export type Route = {
    [k in keyof Routes]: { type: k; value: Routes[k] };
}[keyof Routes];
export const mkRoute: { [k in keyof Routes]: (v: Routes[k]) => Route } = {
    RouteFinder: (r) => ({ type: "RouteFinder", value: r }),
    RouteGasStation: (r) => ({ type: "RouteGasStation", value: r }),
};
export const foldRoute =
    <a>(matches: {
        [k in keyof Routes]: (value: Routes[k]) => a;
    }) =>
    (r: Route): a =>
        matches[r.type](r.value as any);
export const formatRoute: RouteFormatter<Route> = foldRoute({
    RouteFinder: formatRouteFinder,
    RouteGasStation: formatRouteGasStation,
});
export const replaceRoute = (route: Route) =>
    global.history.pushState(undefined, "", formatRoute(route));
export const navigateRoute = (
    route: Route,
    // eslint-disable-next-line @typescript-eslint/ban-types
    options?: NavigateOptions<{}>
) => navigate(formatRoute(route), options);
