import React from "react";

import { SSRProvider } from "react-aria";

import GraphqlLaravelLighthouseProvider from "../../providers/GraphqlLaravelLighthouse";
import { PageDataProvider } from "../shared/Page";
import ThemeProvider from "../Theme";

const ContextShared = ({ children }) => (
    <SSRProvider>
        <GraphqlLaravelLighthouseProvider>
            <PageDataProvider>
                <ThemeProvider>{children}</ThemeProvider>
            </PageDataProvider>
        </GraphqlLaravelLighthouseProvider>
    </SSRProvider>
);
export default ContextShared;
