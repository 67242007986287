import React from "react";

import { Helmet } from "react-helmet";

import { ccm19 } from "../../../config";
import { toURLWithSearch } from "../RouteLink/Route";
import BlockStyles from "./BlockStyles";

const ccm19URL = toURLWithSearch("https://cloud.ccm19.de/app.js", ccm19);

const CookieConsent = () => (
    <>
        <Helmet>
            <script src={ccm19URL.href} referrerPolicy="origin"></script>
        </Helmet>

        <BlockStyles
            blockedPrefixes={["https://fonts.googleapis.com/css?family="]}
        />
    </>
);
export default CookieConsent;
