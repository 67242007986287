import * as num from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";

import { Branded, Int } from "../../../../utils/Brand";
import { Normal } from "./NormalOrTravis";

export type GasStationTypeIdBrand = {
    readonly GasStationTypeId: unique symbol;
};
export type GasStationTypeId = Branded<Int, GasStationTypeIdBrand>;

export enum GasStationOwner {
    hoyer = "hoyer",
    partner = "partner",
    acceptancePartner = "acceptancePartner",
}

export enum ActualGasStationType {
    gasStation = "gasStation",
    restaurant = "restaurant",
    truckStop = "truckStop",
}

export type GasStationType = {
    id: Normal<GasStationTypeId>;
    name: string;
    gasStation: boolean;
    vendingMachineOnly: boolean;
    owner: GasStationOwner;
    type: ActualGasStationType;
};

export const IdOrd = num.Ord as Ord_.Ord<GasStationTypeId>;
export const Ord = Ord_.fromCompare<GasStationType>((a, b) =>
    IdOrd.compare(a.id.value, b.id.value)
);
