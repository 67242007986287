import * as Ord_ from "fp-ts/lib/Ord";

import * as GasStationType from "./GasStationType";
import * as NormalOrTravis from "./NormalOrTravis";
import * as TravisStationType from "./TravisStationType";

export type StationType =
    | GasStationType.GasStationType
    | TravisStationType.TravisStationType;
export type StationTypeId = StationType["id"];

export const stationTypeIsGasStation = (
    st: StationType
): st is GasStationType.GasStationType => NormalOrTravis.isNormal(st.id);
export const stationTypeIsTravisStation = (
    st: StationType
): st is TravisStationType.TravisStationType => NormalOrTravis.isTravis(st.id);

export const IdOrd = NormalOrTravis.getOrd(
    GasStationType.IdOrd,
    TravisStationType.IdOrd
) as Ord_.Ord<StationTypeId>;
export const Ord = Ord_.fromCompare<StationType>((a, b) =>
    IdOrd.compare(a.id, b.id)
);
