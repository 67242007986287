import * as Ord_ from "fp-ts/lib/Ord";

import * as Service from "./Service";

export type RawServiceProperty<a> = { type: "Service"; value: a };

export type ServiceProperty = RawServiceProperty<Service.Service>;
export type ServicePropertyId = RawServiceProperty<Service.ServiceId>;
export type TravisPropertyId = { type: "Travis" };
export type TravisProperty = TravisPropertyId;

export type Property = ServiceProperty | TravisProperty;

export const serviceProperty = (value: Service.Service): Property => ({
    type: "Service",
    value,
});
export const travisProperty: Property = {
    type: "Travis",
};

export const propertyIsServiceProperty = (p: Property): p is ServiceProperty =>
    p.type === "Service";
export const propertyIsTravisProperty = (p: Property): p is TravisProperty =>
    p.type === "Travis";

export const fold =
    <a>(onService: (s: Service.Service) => a, onTravis: () => a) =>
    (p: Property) =>
        propertyIsServiceProperty(p)
            ? onService(p.value)
            : propertyIsTravisProperty(p)
              ? onTravis()
              : (undefined as never);

export const Ord = Ord_.fromCompare<Property>((a, b) =>
    propertyIsTravisProperty(a)
        ? propertyIsTravisProperty(b)
            ? 0
            : -1
        : propertyIsTravisProperty(b)
          ? 1
          : Service.Ord.compare(a.value, b.value),
);

export type PropertyId = ServicePropertyId | TravisPropertyId;

export const propertyId = fold(
    (s) => servicePropertyId(s.id),
    () => travisPropertyId,
);

export const servicePropertyId = (value: Service.ServiceId): PropertyId => ({
    type: "Service",
    value,
});
export const travisPropertyId: PropertyId = {
    type: "Travis",
};

export const propertyIdIsServicePropertyId = (
    p: PropertyId,
): p is ServicePropertyId => p.type === "Service";
export const propertyIdIsTravisPropertyId = (
    p: PropertyId,
): p is TravisPropertyId => p.type === "Travis";

export const foldId =
    <a>(onService: (s: Service.ServiceId) => a, onTravis: () => a) =>
    (id: PropertyId) =>
        propertyIdIsServicePropertyId(id)
            ? onService(id.value)
            : propertyIdIsTravisPropertyId(id)
              ? onTravis()
              : (undefined as never);

export const IdOrd = Ord_.fromCompare<PropertyId>((a, b) =>
    propertyIdIsTravisPropertyId(a)
        ? propertyIdIsTravisPropertyId(b)
            ? 0
            : -1
        : propertyIdIsTravisPropertyId(b)
          ? 1
          : Service.IdOrd.compare(a.value, b.value),
);
