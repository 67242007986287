import * as Ord_ from "fp-ts/lib/Ord";

import * as NormalOrTravis from "./NormalOrTravis";
import * as NormalService from "./NormalService";
import * as TravisService from "./TravisService";

export type Service = NormalService.NormalService | TravisService.TravisService;
export type ServiceId = Service["id"];

export const serviceIsNormalService = (
    s: Service,
): s is NormalService.NormalService => NormalOrTravis.isNormal(s.id);
export const serviceIsTravisService = (
    s: Service,
): s is TravisService.TravisService => NormalOrTravis.isTravis(s.id);

export const IdOrd = NormalOrTravis.getOrd(
    NormalService.IdOrd,
    TravisService.IdOrd,
);
export const Ord = Ord_.fromCompare<Service>((a, b) =>
    IdOrd.compare(a.id, b.id),
);
