import { useCallback, useEffect, useRef, useState } from "react";

import create from "zustand";

import { apiFetchProtected } from "../apiBindings";
import { useIsAuthenticated } from "../providers/AuthProvider";
import { storageLocal } from "../utils/StorageHandler";

export enum Permission {
    ROLE_ASSUME = "role:assume",
    ROLE_UPDATE_HIERARCHY = "role:update-hierarchy",
    ROLE_CREATE = "role:create",
    ROLE_UPDATE = "role:update",
    ROLE_DELETE = "role:delete",
    ROLE_READ = "role:read",
    ROLE_CREATE_CUSTOMER = "role:create-customer",
    ROLE_UPDATE_CUSTOMER = "role:update-customer",
    ROLE_DELETE_CUSTOMER = "role:delete-customer",
    ROLE_READ_CUSTOMER = "role:read-customer",
    USER_ASSIGN_ROLE = "user:assign-role",
    USER_ASSIGN_CUSTOMER_ROLE = "user:assign-customer-role",
    INVOICE_LIST = "invoice:list",
    TRANSACTION_LIST = "transaction:list",
    CARD_LIST = "card:list",
    VEHICLE_LIST = "vehicle:list",
    SERVICE_LIST = "service:list",
    FAQ_LIST = "faq:list",
    INVOICE_DOWNLOAD = "invoice:download",
    CARD_CREATE_REPLACEMENT = "card:create-replacement",
    CARD_CREATE_NEW = "card:create-new",
    CARD_DEACTIVATE = "card:deactivate",
    CARD_LIST_LIMIT = "card:list-limit",
    VEHICLE_READ_CONNECTION = "vehicle:read-connection",
    CARD_UPDATE = "card:update",
    CARD_UPDATE_LIMIT = "card:update-limit",
    VEHICLE_CONNECT_TO_CARD = "vehicle:connect-to-card",
    VEHICLE_DISCONNECT_FROM_CARD = "vehicle:disconnect-from-card",
    CARD_UPDATE_ACTIVATION_TIME = "card:update-activation-time",
    VEHICLE_CREATE = "vehicle:create",
    VEHICLE_UPDATE = "vehicle:update",
    VEHICLE_DELETE = "vehicle:delete",
    USER_ACTIVATE_CUSTOMER = "customer:activate-customer",
    USER_REQUEST_ACTIVATION = "user:request-activation",
    USER_CONNECT_TO_CUSTOMER = "user:connect-to-customer",
    USER_GENERATE_ACTIVATION_CODE = "user:generate-activation-code",
    USER_DISCONNECT_FROM_CUSTOMER = "user:disconnect-from-customer",
    CARD_LIST_ALL = "card:list-all",
    CARD_UPDATE_ALL = "card:update-all",
    CARD_CREATE_ALL = "card:create-all",
    CARD_DELETE_ALL = "card:delete-all",
    CARD_CONNECT_TO_USER = "card:connect-to-user",
    CARD_DISCONNECT_FROM_USER = "card:disconnect-from-user",
    CARD_DYN_PIN = "card:dyn-pin",
    AUSSCHREIBUNG_MANAGE = "ausschreibung:manage",
    AUSSCHREIBUNG_READ = "ausschreibung:read",
    CUSTOMER_ADMIN_LIST = "customer-admin:list",
    CUSTOMER_ADMIN_CREATE = "customer-admin:create",
    CUSTOMER_ADMIN_DELETE = "customer-admin:delete",
    CUSTOMER_ACCESS_LIST = "customer:access:list",
    CUSTOMER_ACCESS_CONNECT = "customer:access:connect",
    CUSTOMER_ACCESS_DISCONNECT = "customer:access:disconnect",
    LIST_ALL_USERS = "customer:access:list-all",
}

type CustomerPermissionKey = `CUSTOMER_${number}`;

type HoyerAcl = {
    USER: Permission[];
    CUSTOMER_NUMBERS: {
        [key: CustomerPermissionKey]: Permission[];
    };
};

type AuthUser = {
    id: number;
    keycloak_id: string;
    email: string;
    name: string;
    email_verified_at: string | null;
    created_at: string | null;
    updated_at: string | null;
    acl: HoyerAcl;
};

type AuthUserResponse = {
    data: AuthUser;
};

interface HoyerAclStore {
    user: AuthUser | null;
    setUser: (user: AuthUser | null) => void;
    getUser: () => AuthUser | null;
}

export const useHoyerAclStore = create<HoyerAclStore>((set, get) => ({
    user: null,
    setUser: (user) => {
        set({ user });
    },
    getUser: () => {
        return get().user;
    },
}));

// Since this hook is being called on a lot of components at once, make sure the endpoint is just called once.
// This is a Singleton prevents that the endpoint is being spammed
let fetchUserPromise: Promise<AuthUser> | null = null;

export const useHoyerAcl = () => {
    const isAuthenticatedInKeycloak = useIsAuthenticated();
    const [isLoading, setIsLoading] = useState(false);
    const store = useHoyerAclStore();
    const isMounted = useRef(true);

    const fetchUserInfo = useCallback(async () => {
        if (!fetchUserPromise) {
            fetchUserPromise = apiFetchProtected<AuthUserResponse>(
                "/hoyer-acl/me"
            )
                .then((response) => response.data)
                .finally(() => {
                    fetchUserPromise = null;
                });
        }
        return fetchUserPromise;
    }, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const loadUser = async () => {
            if (
                isAuthenticatedInKeycloak &&
                store.getUser() === null &&
                !isLoading
            ) {
                setIsLoading(true);
                try {
                    const user = await fetchUserInfo();
                    if (isMounted.current) {
                        store.setUser(user);
                    }
                } catch (error) {
                    console.error("Error fetching user info:", error);
                } finally {
                    if (isMounted.current) {
                        setIsLoading(false);
                    }
                }
            }
        };
        void loadUser();
    }, [isAuthenticatedInKeycloak, store, fetchUserInfo]);

    /**
     * Checks if the user has a permission either direct permission or indirect through
     * his currently selected customer.
     * @param permission
     * @param onFail
     */
    const can = (permission: Permission, onFail?: () => void): boolean => {
        const authUser = store.user;
        if (!authUser) {
            // Execute the callback if a callback function is provided e.g., redirect or something.
            onFail && onFail();
            return false;
        }

        //If a customer is set, check first if the user has the permission for the customer;
        const customerNumber =
            storageLocal.getInt("selectedCustomerNumber") ?? null;
        if (
            customerNumber &&
            authUser.acl.CUSTOMER_NUMBERS[`CUSTOMER_${customerNumber}`]
        ) {
            const hasPermissionForCustomer =
                authUser.acl.CUSTOMER_NUMBERS[
                    `CUSTOMER_${customerNumber}`
                ].includes(permission);

            if (hasPermissionForCustomer) {
                return true;
            }
        }
        //Check if the user has direct permission otherwise.
        if (authUser.acl.USER.includes(permission)) {
            return true;
        }
        onFail && onFail();
        return false;
    };

    /**
     * Checks if a user has at least one of the given permissions.
     * @param permissions
     * @param onFail
     */
    const canAny = (
        permissions: Permission[],
        onFail?: () => void
    ): boolean => {
        for (const permission of permissions) {
            if (can(permission)) {
                return true;
            }
        }
        onFail && onFail();
        return false;
    };

    const getUser = () => {
        return store.getUser();
    };

    return {
        can,
        canAny,
        getUser,
    };
};
