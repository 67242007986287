import * as num from "fp-ts/lib/number";
import * as Ord_ from "fp-ts/lib/Ord";
import * as Ordering from "fp-ts/lib/Ordering";

import { Branded, Int } from "../../../../utils/Brand";
import { Normal } from "./NormalOrTravis";

export type NormalServiceIdBrand = { readonly ServiceId: unique symbol };
export type NormalServiceId = Branded<Int, NormalServiceIdBrand>;

export type NormalService = {
    id: Normal<NormalServiceId>;
    name: string;
};

const orderedPrefix = [
    // truck wash
    8 as NormalServiceId,
    // car wash
    7 as NormalServiceId,
];
export const IdOrd = Ord_.fromCompare<NormalServiceId>((a, b) => {
    const aIx = orderedPrefix.indexOf(a);
    const bIx = orderedPrefix.indexOf(b);

    return Ordering.Monoid.concat(
        num.Ord.compare(
            aIx === -1 ? Infinity : aIx,
            bIx === -1 ? Infinity : bIx
        ),
        (num.Ord as Ord_.Ord<NormalServiceId>).compare(a, b)
    );
});
export const Ord = Ord_.fromCompare<NormalService>((a, b) =>
    IdOrd.compare(a.id.value, b.id.value)
);
