import * as Ord_ from "fp-ts/lib/Ord";

import * as GasStation from "./GasStation";
import * as NormalOrTravis from "./NormalOrTravis";
import * as TravisStation from "./TravisStation";

export type Station = GasStation.GasStation | TravisStation.TravisStation;
export type StationId = Station["id"];

export const IdOrd = NormalOrTravis.getOrd(
    GasStation.IdOrd,
    TravisStation.IdOrd
) as Ord_.Ord<StationId>;
